import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: 'translate(34px, 20px) scale(1);',
    },
    '&.Mui-focused .MuiInputLabel-outlined': {
      color: 'purple',
    },
  },
  inputRoot: {
    color: 'purple',
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'purple',
    },
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  pageSubTitle: {
    marginTop: '1rem',
    paddingLeft: '1rem',
    fontSize: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  inputSourceButtonContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    display: 'flex',
    margin: '1.5rem 0 0 0',
    backgroundColor: '#818181',
    padding: '0.2rem',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 0.5rem!important',
    },
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 2rem!important',
    border: 'none!important',
    width: '9rem',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  cardGrid: {
    //border: "1px solid #fafafa",
    columnGap: '15px',
  },
  card: {
    //justifyContent: "stretch",
    display: 'flex',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    padding: '.7rem',
    paddingBottom: '.5rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '25rem',
    marginBottom: '1rem',
    //marginRight: "1rem",
    //minHeight: "2rem",
    [theme.breakpoints.down('sm')]: {
      maxWidth: '25rem',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down(476)]: {
      fontSize: '0.75rem',
      padding: '.4rem',
      paddingBottom: '.3rem',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    //height: "6.7rem",
  },
  cardSecondColumn: {
    /*
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-5%",
    },
    */
    flex: 'vertical',
  },
  cardLabelUsername: {
    display: 'grid',

    //placeItems: "center",
    textOverflow: 'ellipsis',
  },
  cardLabel: {
    flex: 1,
    marginLeft: '0.3rem',
    display: 'flex',
    alignItems: 'top',
    fontSize: '1.3rem',
    flexWrap: 'wrap',
    height: '3.02rem',
    display: 'inline-block',
    overflow: 'hidden',
    overflowWrap: 'anywhere',

    //whiteSpace: "nowrap",
    //textOverflow: "ellipsis",
  },
  cardUsername: {
    alightItems: 'flex-end',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.9rem',
    textOverflow: 'ellipsis',
    color: '#d3d3d3',
    height: '0.8rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.8rem',
    },
  },
  editIcon: {
    width: '1.2rem!important',
    marginLeft: '0.3rem',
    cursor: 'pointer',
  },
  cardTimestamp: {
    //marginTop: "3.3rem",
    alightItems: 'bottom',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.7rem',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.6rem',
    },
    //whiteSpace: "nowrap",
  },
  cardLikes: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '1.2rem',
  },
  cardBottomRight: {
    justifyContent: 'flex-end',
  },
  numberPlayed: {
    color: '#d3d3d3',
    fontSize: '0.8rem',
    //marginLeft: "",
    //marginTop: ".2rem",
    marginLeft: '0.3rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.7rem',
    },
  },
  creations: {
    color: '#d3d3d3',
    fontSize: '0.6rem',
    //marginLeft: "",
    //marginTop: ".2rem",
    marginLeft: '.2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: '0.07rem',
    //textOverflow: "ellipsis",
    [theme.breakpoints.down(476)]: {
      fontSize: '0.5rem',
    },
  },
  creationsGrid: {
    alignItems: 'flex-end',
    marginBotton: '0.5rem',
  },
  cardStatus: {
    textAlign: 'center',
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '0.8rem',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '4rem',
      marginLeft: '0rem',
    },
  },

  cardAction: {
    cursor: 'pointer',
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1.3rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0',
    },
    padding: '1rem',
    color: '#161616',
  },
  tooltipHeader: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1rem',
  },
  tooltipSubeaderCenter: {
    alignSelf: 'center',
    marginTop: '1.3rem',
    marginBottom: '0.8rem',
    fontSize: '1.1rem',
    fontWeight: '500',
  },
  tooltipTextContainer: {
    display: 'flex',
  },
  tooltipText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      lineHeight: '1rem',
    },
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  tooltipTextNumber: {
    marginRight: '0.2rem',
  },
  tooltipTextBullet: {
    marginLeft: '0.5rem',
    marginRight: '0.8rem',
  },
  searchbar: {
    [theme.breakpoints.down('s')]: {
      padding: '0.2rem 0.5rem!important',
      width: '10rem',
      height: '5rem',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    margin: '0.3rem 0 0.5rem 0',
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    height: '2.5rem',
    borderRadius: '0.2rem',
    border: 'none!important',
    padding: '1rem',
    color: '#000',
    fontSize: '0.9rem',
    fontWeight: '400',
    outline: 'none',
  },
  infoIconAction: {
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  infoIconImg: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '0',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
  },
  infoIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0.2rem',
    color: '#161616',
  },
  infoIconTextContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    color: '#161616',
  },
  infoIconTextLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoIconTextBold: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
  infoIconText: {
    fontSize: '0.9rem',
    fontWeight: '400',
  },
  pagination: {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#ffffff',
    margin: '1rem 0',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      //padding: "0.7rem 1rem",
      fontSize: '1rem',
    },

    [theme.breakpoints.up('m')]: {
      marginLeft: '50rem',
    },
    [theme.breakpoints.down(1134)]: {
      width: '6.3rem',
    },

    backgroundColor: '#CAFF73',
    padding: '0.6rem 0 0 0',
    //margin: "0 0 0 0",
    margin: '.3rem 0 .5rem 0',
    width: '7.0rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    textAlign: 'center',
    cursor: 'pointer',
    height: '2.5rem',
    //alignItems: "center",
    //display: "flex",
    verticalTextAlign: 'center',
  },
  searchButtonContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',
    //border: "1px solid #fafafa",

    display: 'flex',
    justifyContent: 'space-between',
  },
  coverImg: {
    /*
    [theme.breakpoints.down("xs")]: {
      maxWidth: "5.5rem",
      width: "100%",
    },
    */
    userSelect: 'none',
    width: '5.5rem',
    height: '5.5rem',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '1',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '0.3rem',
    border: '0.07rem solid #808080',
  },
  playButtonImg: {
    userSelect: 'none',
    //display: "inline-block",
    //marginLeft: "0.5rem",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '50px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  playButtonGrid: {
    direction: 'row',
    justifyContent: 'flex-end',
  },
  likeButtonImg: {
    marginTop: '3.2rem',
    userSelect: 'none',
    display: 'inline-block',
    marginLeft: '0.5rem',
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteImg: {
    userSelect: 'none',
    //display: "inline-block",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteFullImg: {
    userSelect: 'none',
    //display: "inline-block",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
    //color: "#ff0000",
    color: '#d32f2f',
  },
  numberLikes: {
    //marginTop: "3.5rem",
    fontSize: '0.8rem',
    marginLeft: '.1rem',
    marginRight: '.1rem',
  },
  tagAutoComplete: {
    [theme.breakpoints.down('s')]: {
      padding: '0.2rem 0.5rem!important',
      width: '10rem',
      height: '5rem',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    //margin: "0.3rem 0 0.5rem 0",
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    borderRadius: '0.2rem',
    border: 'none!important',
    //padding: "1rem",
    color: '#000',
    fontSize: '0.9rem',
    fontWeight: '400',
    outline: 'none',
    //color: "#fff",
    backgroundColor: '#fff',
    //fontColor: "#fff",
    //borderColor: "#fff",
  },
  sortSelect: {
    //color: "#fff",
    backgroundColor: '#fff',
    height: '2.5rem',
    [theme.breakpoints.down(1134)]: {
      width: '6',
    },
    width: '7',

    //fontColor: "#fff",
    //borderColor: "#fff",
  },
  filterSortContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',
    //border: "1px solid #fafafa",

    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
}));

export { useStyles };
